"use client";

import { useRef } from "react";
import { useInView, motion } from "framer-motion";

const variant = {
	inView: {
		y: 0,
		opacity: 1,
	},
	offView: {
		y: 100,
		opacity: 0,
	},
};

const Animate = ({ children, className }: { children: React.ReactNode | React.ReactNode[]; className?: string }) => {
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });

	return (
		<section ref={ref} className={className}>
			<motion.div
				variants={variant}
				animate={isInView ? "inView" : "offView"}
				initial={false}
				transition={{
					bounce: 30,
					ease: "easeIn",
				}}
				// sx={{
				// 	transform: isInView ? "none" : "translateY(100px)",
				// 	opacity: isInView ? 1 : 0,
				// 	transition:
				// 		"opacity 0.8s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s,transform 0.8s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
				// }}
			>
				{children}
			</motion.div>
		</section>
	);
};
export default Animate;
