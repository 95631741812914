"use client";
import Link from "next/link";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useScroll, useTransform, motion } from "framer-motion";

import Button from "@components/ui/form/button";
import { HeroProps } from "@src/types/section";
import { contentfulLoader } from "./image";

const Hero = ({ section }: { section: HeroProps }) => {
	const { scrollYProgress } = useScroll({
		offset: ["start start", "end start"], // remove this if your container is not fixed height
	});
	const translate = useTransform(scrollYProgress, [0, 1], [0, 500]);

	return (
		<div className="h-[25rem] lg:h-[40rem] relative py-32 lg:py-64 overflow-hidden w-full">
			<motion.div
				className="absolute top-0 bg-cover bg-no-repeat bg-center -z-1 w-full h-full"
				style={{
					y: translate,
					backgroundImage: `linear-gradient(180deg, rgba(0, 0, 30, 0) 20%, rgba(0, 0, 0, 0.8) 95%), url(${contentfulLoader(
						{
							src: section?.heroImage?.url ?? "",
							width: 1920,
							quality: 75,
						}
					)})`,
					backgroundPositionX: "center",
					backgroundPositionY: "center",
				}}
			/>
			<div className="max-w-4xl mx-auto text-accent z-10 px-4">
				<div className="flex justify-between">
					<div>
						<h1 className="text-6xl font-bold">{section?.heroTitle}</h1>

						{section?.heroText && <div className="">{documentToReactComponents(section?.heroText?.json)}</div>}

						{section?.heroCta && (
							<Link href={section?.heroCta?.url}>
								<Button className="mt-12">{section?.heroCta?.label}</Button>
							</Link>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
