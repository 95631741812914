"use client";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { FiAlertTriangle, FiCheck } from "react-icons/fi";

import Animate from "@components/ui/animation";
import { LogoGridProps } from "@src/types/section";

import ContentfulImage from "./image";

const LogoGrid = ({ section }: { section: LogoGridProps }) => {
	if (!section?.listCollection?.items?.length) return null;

	return (
		<div className="content-container">
			<Animate>
				<h2 className="py-20 text-center">{section?.name}</h2>
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-items-center gap-12 items-center justify-center px-8">
					{section?.listCollection?.items?.map((partner) => (
						<ContentfulImage
							key={JSON.stringify(partner)}
							src={partner?.logo?.url}
							alt={partner.name}
							width={300}
							height={300}
							style={{ maxWidth: "100%", height: "auto" }}
						/>
					))}
				</div>
				<div className="flex flex-col lg:flex-row gap-8 items-center max-w-3xl px-4 lg:px-0 mx-auto my-18">
					{section?.subicon ? (
						<div className="flex flex-row gap-4 items-center">{getSubIcon(section?.subicon)}</div>
					) : null}
					<div className="prose max-w-none">
						{section?.subtitle ? <h3 className="text-left">{section.subtitle}</h3> : null}
						{section?.subtext && <div className="text-accent">{documentToReactComponents(section?.subtext?.json)}</div>}
					</div>
				</div>
			</Animate>
		</div>
	);
};

const getSubIcon = (icon: string) => {
	switch (icon) {
		case "check":
			return <FiCheck size={84} />;
		case "warning-triangle":
			return <FiAlertTriangle size={84} className="text-red-500" />;
		default:
			return null;
	}
};

export default LogoGrid;
